import React from "react";
import arrow from "../assets/images/arrow.svg";

function BookingCard({ img, title, description, booking, link }) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-12 size-mobile">
      <div>{img}</div>
      <div className="height-div">
        <h3>{title}</h3>
        <p className="description">{description}</p>
         </div>
        <a
          className="d-flex booking-arrow justify-content-between px-4 text-decoration-none"
          href={link}
        >
          <div className="booking ">{booking}</div>
          <img src={arrow} alt="" className="" />
        </a>
     
    </div>
  );
}

export default BookingCard;
