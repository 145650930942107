import React from "react";
import logo from "./assets/images/logo.svg";
import bride1 from "./assets/images/bride1.png";
import bride2 from "./assets/images/bride2.png";
import bride3 from "./assets/images/bride3.png";
import BookingCard from "./components/BookingCard";
import "./App.css"
function App() {

  const data = [
    {
      img: <img src={bride1} alt="" className="booking-img" />,
      title: "Bridal Market Appointment",
      description: "To book an appointment with Elissar bridal at an upcoming bridal market, click below:",
      booking: "BOOK MARKET APPOINTMENT",
      link: 'https://marketappointment.elissarbridal.com',
    },
    {
      img: <img src={bride2} alt="" className="booking-img" />,
      title: "In-store Visit",
      description: "To book a store visit to your store by the Elissar Bridal team to show you our newest collections, click below",
      booking: "BOOK STORE VISIT",
      link: 'https://storevisit.elissarbridal.com',
    },
    {
      img: <img src={bride3} alt="" className="booking-img" />,
      title: "Contact Us",
      description: "To contact us, click the following form to send us a message:",
      booking: "CONTACT US fORM",
      link: 'https://elissarbridal.com/contact',
    },
  ];
  return (
    <>
      <div className="position-relative">
        <div className="bookBackground">
          <div className="text-center max-height-mobile">
            <img src={logo} alt="" className="mt-4 " />
            <hr />
            <h1 className="mt-5">Book a market appointment,<br /> in-store visit, or contact us:</h1>
            <p className="pt-2 book-p">To book an appointment at an upcoming bridal market, or to schedule a store visit by the Elissar Team, or to simply get in touch with us, choose the appropriate online form below:</p>
          </div>
          <hr className="hr" />
        </div>
        <div className="row margin-bottom">
          {data.map(({ img, title, booking, description, link }, index) => {
            return (
              <BookingCard
                img={img}
                title={title}
                key={index}
                booking={booking}
                description={description}
                link={link}
              />
            );
          })}
        </div>

        <footer className="d-flex justify-content-between">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>

          <div className=" rights">
            <div className="footer-text">
              © 2022 Elissar Bridal | All Rights Reserved
            </div>
            <div className="mt-4 footer-btn">
              Go to ElissarBridal.com
            </div>
          </div>
          <div className="display pe-5 me-5">
            <p className="pe-3 pt-3">for more info :</p>
            <button>Go to ElissarBridal.com</button>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
